//Sorry about the mess

@mixin framed-icon($color, $top: -9px, $left: -3px) {
  position: relative;
  &::after {
    content: (" ");
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: $color;
    width: 36px;
    height: 36px;
    top: $top;
    left: $left;
    z-index: -1;
  }
}

$lelis-color: #0076d1;
$primary-bg: #303030;

body {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background-color: #1f1f1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
  position: fixed;
  bottom:0;
  width:100%;
	padding: 0px 0;
	background-color: $lelis-color;

.mail {
  float:right;
  padding-top: 6px;
  margin-right: 20px;
  a,a:visited{
    
    display: block;
		color:white;
		text-decoration: none;
	}
}
	
	.contact-icons {
    float:right;

    a > span {
      margin-right: 5px;
      margin-left: 5px;
      width:20px;
      height:20px;
      display: inline-block;
      background-size: contain;
      &.linkedin{ background-image: url("../public/contact/linkedin.svg") };
      &.hackerrank{ background-image: url("../public/contact/hackerrank.svg") };
      &.stackoverflow{ background-image: url("../public/contact/stackoverflow.svg") };
      &.reddit{ background-image: url("../public/contact/reddit.svg"); width: 25px; height: 25px; margin-bottom: -3px; };
    }

    }
}



.menucontainer {
  background-color: #181818;
  color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 2;
  .MuiTab-labelIcon {
    border: solid 1px #1f1f1f;
  }
  .MuiTab-wrapper {
    flex-direction: row;
  }

  .MuiButtonBase-root.Mui-selected {
    background-color: #1f1f1f;
  }
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
  .MuiIconButton-root {
    color: #ffffff;
  }
}

div.MuiPaper-root {
  background: #1f1f1f;
  color: white;
  .MuiListItemIcon-root {
    color: #ffffff;
  }
}

.logo-image {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 100px;
  background-image: url("../public/next.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  clip-path: circle(42px at center);
  margin-top: 26px;
}

.logo-image-home {
  display: block;
  width: 300px;
  height: 250px;

  background: url("../public/next.png") center no-repeat;
}

.home {
  color: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 45px);

  h2 {
    text-align: center;
  }
}

.aboutme-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  color: white;

  .footer-section {
    height: 50vh;
  }
  .fixed-content {
    position: fixed;
    top: -45px;
    background-color: #181818;
    width: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    h2 {
      color: white;
      font-size: 1.2em;
      font-weight: normal;
      margin-left: 70px;
    }
  }

  .scrollabe-content {
    display: flex;
    flex-direction: column;
    flex-grow: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .image-mask {
      z-index: 3;
      .image-transition {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-attachment: local;
        background-position-y: 0;
        background-position-x: center;
        margin: auto;
        position: absolute;

        &.background-1 {
          z-index: 1;
          background-image: url("../public/lelis-draw.jpg");
        }

        &.background-2 {
          z-index: 2;
          opacity: 0;
          background-image: url("../public/lelis.jpg");
        }
      }

      position: fixed;
      width: 200px;
      height: 200px;
      left: calc(-100px + 50%);
      top: 40vh;
      margin-left: 0;
      clip-path: circle(100px at center);
      pointer-events: none;
      transition: all 0.3s ease-in-out;
    }

    .section {
      height: 100vh;
      padding: 16px;
      h3 {
        border-bottom: 1px solid #4e4e4e;
        padding-bottom: 8px;
      }
      &.text {
        height: auto;
        text-align: center;
        p,
        h3 {
          text-align: left;
        }
      }
    }
  }

  //Handle animations
  &.fix-to-top {
    .scrollabe-content .image-mask {
      width: 60px;
      height: 60px;
      left: 2px;
      top: 42px;
      clip-path: circle(20px at center);
      margin-left: 0;
    }

    .fixed-content {
      top: 45px;
      opacity: 1;
    }
  }
  strong {
    font-weight: bolder;
    color: $lelis-color;
  }
}
.work-container {
  padding-top: 45px;
  color: white;
  .section {
    padding: 16px;
    h3 {
      border-bottom: 1px solid #4e4e4e;
      padding-bottom: 8px;
    }
    &.text {
      height: auto;
    }
  }
}

.skill-level-bar {
  padding: 16px;
  position: relative;
  .level-bar {
    position: relative;
    width: 100%;
    height: 5px;
    .painted-bar {
      width: 100%;
      background-color: black;
      height: 5px;
      position: absolute;
      overflow: hidden;
      border-radius: 50px;

      &.level-1::after {
        background-color: orange;
        width: 25%;
      }
      &.level-2::after {
        background-color: yellow;
        width: 50%;
      }
      &.level-3::after {
        background-color: greenyellow;
        width: 75%;
      }
      &.level-4::after {
        background-color: green;
        width: 100%;
      }
      &::after {
        content: "";
        width: 0;
        height: 100%;
        position: absolute;
        background-color: green;
        border-radius: 50px;
        animation: bar-level 1s ease-in-out;
      }
    }
  }
  ul.legend {
    padding-top: 0;
    width: 100%;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    margin-top: 5px;
    li {
      width: 10%;
      display: inline-block;
      padding: 0;
      text-align: center;
      margin-left: 8%;
      margin-right: 7%;
    }
  }
}

.skills-container {
  color: white;
  padding-top: 45px;
  padding-left: 20px;

  p {
    margin-left: 16px;
    margin-right: 16px;
  }
  h3 {
    font-size: 1em;
    border-bottom: 1px solid #4e4e4e;
    padding-bottom: 8px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .skill-level-container {
    width: 65vw;
    display: inline-block;
  }

  .skill-icon {
    font-size: 0.8em;
    background-repeat: no-repeat;
    background-position: left 0px;
    width: 10vw;
    height: 30px;
    padding-left: 40px;
    padding-top: 5px;
    background-size: 30px;
    margin-left: 20px;
    display: inline-block;
    margin-right: 16px;
    vertical-align: top;
    /* border: 1px solid green; */

    &.csharp {
      background-image: url("../public/skills/csharp.svg");
    }
    &.dotnet {
      background-image: url("../public/skills/netframework.svg");
    }
    &.java {
      @include framed-icon(white, 0px, -2px);
      background-image: url("../public/skills/java.png");
    }
    &.dart {
      background-image: url("../public/skills/dart.svg");
    }
    &.php {
      background-image: url("../public/skills/Antu_php.svg");
    }
    &.python {
      background-image: url("../public/skills/Python-logo-notext.svg");
    }
    &.nodejs {
      @include framed-icon(white);
      background-image: url("../public/skills/Node.js_logo.svg");
    }
    &.graphql {
      background-image: url("../public/skills/graphql.svg");
    }
    &.solr {
      @include framed-icon(black, -6px, -4px);
      background-image: url("../public/skills/solr.png");
    }
    &.camunda {
      background-image: url("../public/skills/camunda.svg");
    }
    &.cucumber {
      background-image: url("../public/skills/cucumber.png");
      background-size: 100px;
      background-position-x: -35px;
      background-position-y: -13px;
    }
    &.coremedia {
      @include framed-icon(white, -5px);
      background-image: url("../public/skills/CoreMedia_CMS_Logo_-_Stacked.svg.png");
    }
    &.rabbitmq {
      background-image: url("../public/skills/rabbitmq.png");
    }
    &.kafka {
      background-image: url("../public/skills/kafka.png");
    }
    &.html5 {
      background-image: url("../public/skills/HTML5_logo_and_wordmark.svg");
    }
    &.css3 {
      background-image: url("../public/skills/CSS3_logo_and_wordmark.svg");
    }
    &.javascript {
      background-image: url("../public/skills/Unofficial_JavaScript_logo_2.svg");
    }
    &.angular {
      background-image: url("../public/skills/Angular_full_color_logo.svg");
    }
    &.angularjs {
      @include framed-icon(white, -6px);
      background-image: url("../public/skills/angularjs.svg");
    }
    &.jquery {
      background-image: url("../public/skills/jquery.svg");
    }
    &.flash {
      background-image: url("../public/skills/flashplayer_app_RGB.svg");
    }
    &.unity {
      @include framed-icon(white, -3px, -1px);
      background-image: url("../public/skills/unity-3d-vector-icon-logo.svg");
    }
    &.react {
      background-image: url("../public/skills/React-icon.svg");
    }
    &.sencha {
      @include framed-icon(white, -5px);
      background-image: url("../public/skills/extjs_logo-300x259.png");
    }
    &.cordova {
      background-image: url("../public/skills/logo-cordova.svg");
    }
    &.ionic {
      background-image: url("../public/skills/ionic.svg");
    }
    &.react {
      background-image: url("../public/skills/React-icon.svg");
    }
    &.flutter {
      background-image: url("../public/skills/flutterio-icon.svg");
    }
    &.android {
      background-image: url("../public/skills/android-svgrepo-com.svg");
    }
    &.apple {
      background-image: url("../public/skills/Apple_Computer_Logo_rainbow.svg");
    }
    &.windows-phone {
      background-image: url("../public/skills/windows-phone.svg");
    }
    &.sqlite {
      background-image: url("../public/skills/Sqlite-square-icon.svg");
    }
    &.sqlserver {
      background-image: url("../public/skills/logo-sql-server.svg");
    }
    &.postgresql {
      background-image: url("../public/skills/Postgresql_elephant.svg");
    }
    &.mysql {
      background-image: url("../public/skills/mysql-icon.svg");
    }
    &.oracle {
      background-image: url("../public/skills/oracle.svg");
    }
    &.mongodb {
      background-image: url("../public/skills/Antu_mongodb.svg");
    }
    &.firebird {
      background-image: url("../public/skills/Firebird_logo.svg");
    }
    &.docker {
      background-image: url("../public/skills/Docker.svg");
    }
    &.jenkins {
      background-image: url("../public/skills/Jenkins_logo.svg");
    }
    &.kibana {
      background-image: url("../public/skills/elastic-kibana-logo.png");
    }
    &.grafana {
      background-image: url("../public/skills/Grafana_logo.svg.png");
    }
    &.new-relic {
      background-image: url("../public/skills/new_relic_logo_vertical_clear_white.png");
    }
  }

  
}
.lab-container{
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  color: white;
  strong {
    color:$lelis-color
  }
	> p{
		margin-left:16px;
		margin-right:16px;
	}

	.section {
		padding:16px;
		h3{
			border-bottom: 1px solid #4e4e4e;
			padding-bottom:8px;
		}
		&.text {
		  height:auto;
		}
		a,a:visited{
			color:white;
			text-decoration: none;
		}
	  }
	  
	  .work-image{
		  display:block;
		  width:100%;
		  img{
			  width:50vw;
			  display:block;
			  margin-left:auto;
			  margin-right:auto;
		  }
	  }
}


@keyframes bar-level {
  from {
    background-color: orange;
    width: 0;
  }
}

@keyframes circletransition {
  from {
    clip-path: circle(100px at center);
  }

  to {
    clip-path: circle(20px at center);
  }
}
